import React from 'react';

import { SocialIcon } from '../commonStyles/ImagesStyles';

class IconIg extends React.PureComponent {

  render() {

    return (
      <SocialIcon>
        <svg id="ig_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
          <g>
            <path d="M10,1.8c2.67,0,3,0,4,.06a5.61,5.61,0,0,1,1.86.34,3.33,3.33,0,0,1,1.9,1.9A5.61,5.61,0,0,1,18.14,6c.05,1,.06,1.37.06,4s0,3-.06,4a5.61,5.61,0,0,1-.34,1.86,3.33,3.33,0,0,1-1.9,1.9,5.61,5.61,0,0,1-1.86.34c-1,.05-1.37.06-4,.06s-3,0-4-.06A5.61,5.61,0,0,1,4.1,17.8a3.33,3.33,0,0,1-1.9-1.9A5.61,5.61,0,0,1,1.86,14c-.05-1-.06-1.37-.06-4s0-3,.06-4A5.61,5.61,0,0,1,2.2,4.1,3.33,3.33,0,0,1,4.1,2.2,5.61,5.61,0,0,1,6,1.86c1-.05,1.37-.06,4-.06M10,0C7.28,0,6.94,0,5.88.06A7.59,7.59,0,0,0,3.45.52,4.85,4.85,0,0,0,1.68,1.68,4.85,4.85,0,0,0,.52,3.45,7.59,7.59,0,0,0,.06,5.88C0,6.94,0,7.28,0,10s0,3.06.06,4.12a7.59,7.59,0,0,0,.46,2.43,4.85,4.85,0,0,0,1.16,1.77,4.85,4.85,0,0,0,1.77,1.16,7.59,7.59,0,0,0,2.43.46C6.94,20,7.28,20,10,20s3.06,0,4.12-.06a7.59,7.59,0,0,0,2.43-.46,5.19,5.19,0,0,0,2.93-2.93,7.59,7.59,0,0,0,.46-2.43c0-1.06.06-1.4.06-4.12s0-3.06-.06-4.12a7.59,7.59,0,0,0-.46-2.43,4.85,4.85,0,0,0-1.16-1.77A4.85,4.85,0,0,0,16.55.52,7.59,7.59,0,0,0,14.12.06C13.06,0,12.72,0,10,0Z"/>
            <path d="M10,4.86A5.14,5.14,0,1,0,15.14,10,5.14,5.14,0,0,0,10,4.86Zm0,8.47A3.33,3.33,0,1,1,13.33,10,3.33,3.33,0,0,1,10,13.33Z"/>
            <circle cx="15.34" cy="4.66" r="1.2"/>
          </g>
        </svg>
      </SocialIcon>
      );
  }
}

export default IconIg;
