import React from 'react';

import { IconMobileMenu, IconMobileMenuLine1, IconMobileMenuLine2 } from '../commonStyles/ImagesStyles';

class IconArrow extends React.PureComponent {

  render() {

    const {
      isClicked
    } = this.props;

    return (
      <IconMobileMenu>
        <IconMobileMenuLine1 isClicked={isClicked}>
        </IconMobileMenuLine1>
        <IconMobileMenuLine2 isClicked={isClicked}>
        </IconMobileMenuLine2>
      </IconMobileMenu>
      );
  }
}

export default IconArrow;
