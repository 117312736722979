import styled from "styled-components";

export const LargeHeaderTypo = styled.div`
  font-family: 'kawoszeh', sans-serif;
  font-size: 5rem;
  font-weight: 400;
  line-height: 1;

  /* 470px */
  @media (min-width: 29.375rem) {
    font-size: 7rem;
  }

  /* 1024px */
  @media (min-width: 64em) {
    font-size: 9rem;
  }

  /* 1920px */
  @media (min-width: 120rem) {
    font-size: 12rem;
  }
`;

export const LargeHeaderTypoP = styled(LargeHeaderTypo)`
  display: inline-block;
  color: ${ props => props.theme.secondaryColor };
`;

export const LogoTypo = styled.div`
  font-family: 'kawoszeh', sans-serif;
  font-size: 5rem;
  font-weight: 400;
  color: ${ props => props.theme.backgroundColor };
  border-bottom: 2px solid transparent;
  display: inline-block;

  &:hover {
    border-bottom: 2px solid #57a55f;
  }


  /* 1280px */
  @media (min-width: 80em) {
    font-size: 5rem;
    color: ${ props => props.theme.color };
  }

  /* 1366px */
  @media (min-width: 85.375em) {
    font-size: 4rem;
  }

`;

export const SmallHeaderTypo = styled.h3`
  font-family: 'kawoszeh', sans-serif;
  font-size: 3rem;
  font-weight: 400;
  opacity: ${props => props.subtitle ? "0.7" : "1"};

  /* 1024px */
  @media (min-width: 64em) {
    font-size: 3.2rem;
  }
`;

export const MediumHeaderTypo = styled.h3`
  font-family: 'kawoszeh', sans-serif;
  font-size: 5rem;
  font-weight: 400;
  opacity: ${props => props.subtitle ? "0.7" : "1"};
  padding-right: 1rem;

  /* 1024px */
  @media (min-width: 64em) {
    font-size: 5rem;
  }
`;

export const BasicParagraphTypo = styled.h4`
  font-family: 'Jost', sans-serif;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 2;

  ${MediumHeaderTypo} ~ & {
    margin-top: 2rem;
  }

  /* 1024px */
  @media (min-width: 64em) {
    ${MediumHeaderTypo} ~ & {
      margin-top: 5rem;
    }

  }
`;

export const ColorPaletteSubTypo = styled.div`
  font-family: 'Jost', sans-serif;
  font-size: 1.2rem;
  vertical-align: super;
  letter-spacing: 2px;
  font-weight: 400;
`;

export const SmallestAllCapsTypo = styled.div`
  font-family: 'Jost', sans-serif;
  font-size: 1.7rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: ${ props => props.theme.opaqueSmallTextColor };

  /* 1024px */
  @media (min-width: 64em) {
    font-size: 1.3rem;
  }
`;

export const FixedElementsTypo = styled.div`
  font-family: 'Jost', sans-serif;
  font-size: 2rem;
  line-height: 5rem;
  font-weight: 400;
  color: ${ props => props.theme.color };

  /* 1024px */
  @media (min-width: 64em) {
    color: ${ props => props.theme.color };
  }
`;

export const SubTypo = styled.div`
  font-family: 'Jost', sans-serif;
  font-size: 2rem;
  line-height: 4.5rem;
  font-weight: 300;

  ${MediumHeaderTypo} ~ & {
    margin-top: 2rem;
  }

  ${BasicParagraphTypo} ~ & {
    margin-top: 2rem;
  }

  /* 470px */
  @media (min-width: 29.375rem) {
    font-size: 3rem;
    line-height: 6.5rem;
  }

  /* 800px */
  @media (min-width: 50em) {
    font-size: 2rem;
    line-height: 4rem;
  }

  /* 1024px */
  @media (min-width: 64em) {
    font-size: 1.6rem;
    line-height: 3rem;
  }

  ${MediumHeaderTypo} ~ & {
    margin-top: 5rem;
  }


  ${BasicParagraphTypo} ~ & {
    margin-top: 5rem;
  }
`;

export const AllCapsSubTypo = styled.div`
  font-family: 'Jost', sans-serif;
  font-size: 2rem;
  line-height: 4rem;
  font-weight: 300;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: ${ props => props.theme.secondaryColor };

  ${MediumHeaderTypo} ~ & {
   margin-top: 1rem;
  }

  ${BasicParagraphTypo} ~ & {
   margin-top: 5rem;
  }

  /* 1024px */
  @media (min-width: 64em) {
    font-size: 1.6rem;
    line-height: 3rem;

    ${BasicParagraphTypo} ~ & {
     margin-top: 5rem;
    }

    ${MediumHeaderTypo} ~ & {
     margin-top: 5rem;
    }
  }

`;

export const MobileMenuTypo = styled.div`
  font-family: 'Jost', sans-serif;
  font-size: 3rem;
  line-height: 5rem;
  font-weight: 300;
`;

export const ListTypo = styled.div`
  font-family: 'Jost', sans-serif;
  font-size: 2rem;
  font-weight: 400;
  color: ${ props => props.theme.listColor };
`;
