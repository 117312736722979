import styled from "styled-components";


export const FooterSubtitlesContainer = styled.div`
  display: block;
  position: relative;
  margin: 7rem 0 3rem 0;
  width: 100%;
  overflow: hidden;

  /* 800px */
  @media (min-width: 50em) {
    margin: 10rem 0 0 0;
  }
`;


export const FooterSubtitle = styled.div`
  display: inline-block;
  position: relative;
  width: 80%;
  margin-bottom: 5rem;
  float: left;

  /* 800px */
  @media (min-width: 50em) {
    width: 50%;
    text-align: ${ props => props.isRight ? 'right' : 'left' };
  }
`;


export const FooterLine = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 1px;
  margin: 3rem 0;
  background: ${ props => props.theme.secondaryColor };
`
