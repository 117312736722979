import React from 'react';

import { IconOuterCircle } from '../commonStyles/ImagesStyles';

class IconScroll extends React.PureComponent {

  render() {

    const {
      arrowRotation
    } = this.props;

    return (
      <IconOuterCircle arrowRotation={arrowRotation} style={{
        padding: 'calc(2.5rem - 8px)',
        paddingTop: '1.5rem'
      }}>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 15">
          <g>
            <path d="M5,8.22v3.56a5.64,5.64,0,0,0,4.35,5.6A5.51,5.51,0,0,0,16,12V8A5.51,5.51,0,0,0,9.35,2.62,5.64,5.64,0,0,0,5,8.22ZM15,12a4.5,4.5,0,0,1-5.44,4.4A4.61,4.61,0,0,1,6,11.82V8.18A4.61,4.61,0,0,1,9.56,3.6,4.5,4.5,0,0,1,15,8Z" transform="translate(-5 -2.5)"/>
            <rect x="5" y="2.5" width="1" height="3"/>
          </g>
        </svg>
      </IconOuterCircle>
      );
  }
}

export default IconScroll;
