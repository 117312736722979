export default {
  themeMain: {
    'backgroundColor': '#f4f4f4',
    'color': '#262424',
    'secondaryColor': '#c79c73',
    'opaqueSmallTextColor' : '#4a4646',
    'linkColor': '#1fa25a',
    'hoverColor': 'rgba(38, 36, 36, 0.12)',
    'listColor' : '#262424',
    'largeIconColor' : '#c79c73'
  },
  themeInvert: {
    'backgroundColor': '#262424',
    'color': '#fbf6f2',
    'secondaryColor': '#c79c73',
    'opaqueSmallTextColor' : '#605b5b',
    'linkColor': '#1fa25a',
    'hoverColor': 'rgba(244, 244, 244, 0.12)',
    'listColor' : '#f3e2d1',
    'largeIconColor' : '#c79c73'
  },
};
