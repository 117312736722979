import React from 'react';

import { IconOuterCircle } from '../commonStyles/ImagesStyles';

class IconArrow extends React.PureComponent {

  render() {

    const {
      arrowRotation
    } = this.props;

    return (
      <IconOuterCircle arrowRotation={arrowRotation}>
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="13" height ="13" viewBox="0 0 13.2 10.07">
          <path d="M0,20" transform="translate(-3 -5.15)"/>
          <polygon points="7.85 0 7.15 0.71 11.29 4.85 0 4.85 0 5.85 11.29 5.85 7.15 10 7.85 10.71 13.21 5.35 7.85 0"/>
        </svg>
      </IconOuterCircle>
      );
  }
}

export default IconArrow;
