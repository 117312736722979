import React from 'react';

import { Link } from 'gatsby';

import {
  FixedElementScrollDown,
  FixedElementGoBack,
  FixedElementLogo,
  FixedElementMenuContainer,
  FixedElementMenu,
  FixedElementContactButton,
  FixedElementSocialContainer,
  FixedElementSocialLink,
  FixedElementSocialLinkCircle
} from '../commonStyles/FixedElements';
import { FixedElementsTypo, LogoTypo } from '../commonStyles/typoTheme';
import { InlineFloatIconText } from '../commonStyles/ImagesStyles';

import Button from './Button';
import FixedElementsMobileMenu from './FixedElementsMobileMenu';

import IconFb from './IconFb';
import IconIg from './IconIg';
import IconBe from './IconBe';
import IconScroll from './IconScroll';
import IconArrow from './IconArrow';

class AllFixedElements extends React.PureComponent {


  render() {


    return (
      <>
        <FixedElementsMobileMenu current={this.props.current}/>
        <FixedElementScrollDown isCaseStudy={this.props.isCaseStudy}>
          <InlineFloatIconText>
            <IconScroll arrowRotation={'90'}/>
          </InlineFloatIconText>
          <InlineFloatIconText>
            <FixedElementsTypo>
              Scroll down to see our projects
            </FixedElementsTypo>
          </InlineFloatIconText>
        </FixedElementScrollDown>
        <FixedElementGoBack isCaseStudy={this.props.isCaseStudy}>
          <Link to={'/'}>
          <IconArrow arrowRotation={'-180'}/>
          <FixedElementsTypo>
            Go back
          </FixedElementsTypo>
          </Link>
        </FixedElementGoBack>
        <FixedElementLogo>
          <Link to={'/'}>
            <LogoTypo>
              Border3px
            </LogoTypo>
          </Link>
        </FixedElementLogo>
        <FixedElementMenuContainer>
          <FixedElementMenu>
            <Button name={'Process'} href={'/process'} />
          </FixedElementMenu>
          <FixedElementMenu>
            <Button name={'About'} href={'/about'} />
          </FixedElementMenu>
          <FixedElementContactButton>
            <Button name={'Contact'} href={'/contact'} />
          </FixedElementContactButton>
        </FixedElementMenuContainer>
        <FixedElementSocialContainer>
          <FixedElementSocialLink href={'https://www.facebook.com/border3px'} target={'_blank'}>
            <FixedElementSocialLinkCircle>
              <IconFb />
            </FixedElementSocialLinkCircle>
          </FixedElementSocialLink>
          <FixedElementSocialLink href={'https://www.instagram.com/border3px'} target={'_blank'}>
            <FixedElementSocialLinkCircle>
              <IconIg />
            </FixedElementSocialLinkCircle>
          </FixedElementSocialLink>
          <FixedElementSocialLink href={'https://www.behance.net/Border3px'} target={'_blank'}>
            <FixedElementSocialLinkCircle>
                <IconBe />
            </FixedElementSocialLinkCircle>
          </FixedElementSocialLink>
        </FixedElementSocialContainer>
      </>
    );
  }
}

export default AllFixedElements;
