import styled from "styled-components";

export const ThemeContainer = styled.div`  
  ${ props => props.isFullHeight ? 'min-height: 100vh;': ''}
  ${ props => props.theme }
`;

export const MainContainer = styled.div`
  display: block;
  position: relative;
  z-index: 1;
  padding: 0 1.25rem;


  /* 800px */
  @media (min-width: 50em) {
    left: 5em;
    width: calc(100% - 10rem);
  }

  /* 1024px */
  @media (min-width: 64em) {
    left: 10rem;
    width: calc(100% - 20rem);
  }

  /* 1366px */
  @media (min-width: 85.375em) {
    left: 15rem;
    width: calc(100% - 30rem);
  }

  /* 1920px */
  @media (min-width: 120em) {
    left: 42rem;
    width: calc(100% - 84rem);
  }
`;

export const MiddleContainer = styled.div`
  display: block;
  position: relative;
  width: 85%;
  margin: auto;

  /* 1024px */
  @media (min-width: 64em) {
    width: 65%;
  }
`;
