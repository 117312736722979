import styled from "styled-components";
import { ButtonStyle } from './ButtonStyles';

export const FixedElementMain = styled.div`
  display: none;
  position: fixed;
  width: 100%;
  z-index: 300;
  /* 1280px */
  @media (min-width: 80em) {
    width: 25%;
  }
`;

export const FixedElementMenuContainer = styled.div`
  display: none;

  /* 1280px */
  @media (min-width: 80em) {
    z-index: 300;
    display: block;
    position: fixed;

    right: 3rem;
    top: 3rem;
  }

  /* 1366px */
  @media (min-width: 85.375em) {
    display: block;
    right: 5rem;
    top: 4rem;
  }

  /* 1920px */
  @media (min-width: 120em) {
    right: 15rem;
    top: 6rem;
  }
`;

export const FixedElementMenu = styled.div`
  display: inline-block;
  position: relative;
  z-index: 300;
  margin: 0 2rem 0 0;
  text-align: center;
  cursor: pointer;
  float: left;

  ${ButtonStyle} {
    color: #262424;
    background: #f4f4f4;
  }
  &:hover ${ButtonStyle} {
    background: #1fa25a;
    color: #f4f4f4;
  }

`;

  export const FixedElementSocialContainer = styled.div`
    display: none;

    /* 1366px */
    @media (min-width: 85.375em) {
      display: block;
      position: fixed;
      width: 2rem;
      z-index: 300;
      right: 5rem;
      bottom: 4rem;
    }

    /* 1920px */
    @media (min-width: 120em) {
      right: 15rem;
      bottom: 6rem;
    }
`;

export const FixedElementSocialLinkCircle = styled.div`
  display: block;
  position: relative;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  margin-top: 4rem;
  border-radius: 2rem;
  background: transparent;
  cursor: pointer;
  transition: 100ms;

  &:hover {
    background: ${ props => props.theme.hoverColor };
  }
`;

export const FixedElementSocialLink = styled.a`
  display: block;
  position: relative;
`;


export const FixedElementContactButton = styled.div`
  display: inline-block;
  position: relative;
  float: left;
  z-index: 300;
`;

export const FixedElementLogo = styled(FixedElementMain)`
  display: none;

  /* 1280px */
  @media (min-width: 80em) {
    display: block;
    left: 3rem;
    top: 3rem;
  }

  /* 1366px */
  @media (min-width: 85.375em) {
    display: block;
    left: 5rem;
    top: 4rem;
  }

  /* 1920px */
  @media (min-width: 120em) {
    left: 15rem;
    top: 6rem;
  }
`;

export const FixedElementScrollDown = styled(FixedElementMain)`
  display: none;

  /* 1366px */
  @media (min-width: 85.375em) {
    display: ${props => props.isCaseStudy ? 'none' : 'block'};
    left: 10rem;
    bottom: 4rem;
    transform: rotate(-90deg);
    transform-origin: bottom left;
  }

  /* 1920px */
  @media (min-width: 120em) {
    left: 20rem;
    bottom: 6rem;
  }
`;

export const FixedElementScrollDownArrow = styled.div`
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 5rem;
  padding: 1.5rem;
  border: 1px solid #A58861;
  border-radius: 5rem;
`;


export const FixedElementGoBack = styled(FixedElementMain)`
  display: ${props => props.isCaseStudy ? 'block' : 'none'};
  position: relative;
  padding: 3rem 0 0 3rem;


  /* 1280px */
  @media (min-width: 80em) {
    position: fixed;
    padding: 0;
    left: 3rem;
    bottom: 3rem;
  }

  /* 1366px */
  @media (min-width: 85.375em) {
    left: 5rem;
    bottom: 4rem;
  }

  /* 1920px */
  @media (min-width: 120em) {
    left: 15rem;
    bottom: 6rem;
  }
`;



/*-----------------------------------------------MOBILE MENU-----------------------------------------------*/

export const MobileMenuContainer = styled.div`
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  top: ${props => props.menuOpen ? '0' : '150vh'};
  left: 0;
  background: ${ props => props.theme.color };
  color: ${ props => props.theme.secondaryColor };
  z-index: 400;
  padding: 5rem;
  transition: top 400ms ease-out;

  /* 1280px */
  @media (min-width: 80em) {
    display: none;
  }
`;

export const MobileMenuOpenClose = styled.div`
  display: block;
  position: fixed;
  width: 7rem;
  height: 7rem;
  padding: 2rem;
  bottom: 3rem;
  right: 3rem;
  background: ${props => props.menuOpen ? props.theme.color : props.theme.secondaryColor };
  color: ${props => props.menuOpen ? props.theme.secondaryColor : props.theme.color };
  border-radius: 4rem;
  z-index: 402;
  cursor: pointer;
  box-shadow: 1px 1px 16px 0 rgba(36, 36, 38, 0.23);

  /* 1024px */
  @media (min-width: 64em) {
    width: 8rem;
    height: 8rem;
    padding: 2.5rem;
  }

  /* 1280px */
  @media (min-width: 80em) {
    display: none;
  }
`;


export const MobileMenuLogo = styled.div`
  display: block;
  position: relative;
  margin-bottom: 4rem;

  a {
    color: #f4f4f4;
  }
`;

export const MobileMenuElement = styled.div`
  display: inline-block;
  position: relative;
  width: fit-content;
  margin: 0 5rem 2rem 0;
  float: left;
  border-bottom: ${ props => props.isCurrent ? '2px solid #57a55f' : '2px solid transparent'};

  /* 1024px */
  @media screen and (orientation:portrait) {
    width: 100%;
    margin: 0 0 2rem 0;
  }
`;
