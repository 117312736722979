import React from 'react';

import {ThemeProvider} from 'styled-components';

import themes from '../commonStyles/themes';
import { ThemeContainer } from '../commonStyles/Containers';
import { SubTypo, AllCapsSubTypo, SmallestAllCapsTypo } from '../commonStyles/typoTheme';
import { SectionFooterMargin } from '../commonStyles/SectionsStyle';
import { MiddleContainer } from '../commonStyles/Containers';
import { TextContainerColumns, SmallColumnsContainer, TextColumn } from '../commonStyles/TextStyles';

import { FooterSubtitlesContainer, FooterSubtitle, FooterLine } from './styles/FooterStyle';



class SectionFooter extends React.PureComponent {

  render() {
      return (
        <ThemeProvider theme={themes.themeInvert}>
          <ThemeContainer>
            <SectionFooterMargin bgColor ={this.props.bgColor}>
                <MiddleContainer>
                  <TextContainerColumns>
                    <TextColumn hasPaddingRight={true}>
                      <SubTypo>
                        We are digital studio based in United Kingdom.
                      </SubTypo>
                    </TextColumn>
                    <SmallColumnsContainer>
                      <TextColumn>
                        <AllCapsSubTypo>
                          Drop us a line
                        </AllCapsSubTypo>
                      </TextColumn>
                      <TextColumn>
                        <SubTypo>
                          <a href={'mailto:bcc@border3px.com'}  target={'_target'}>bcc@border3px.com</a>
                        </SubTypo>
                      </TextColumn>
                    </SmallColumnsContainer>
                  </TextContainerColumns>
                  <FooterSubtitlesContainer>
                    <FooterLine>
                    </FooterLine>
                    <FooterSubtitle>
                      <SmallestAllCapsTypo>
                        Border3px Ltd is registered in London, United Kingdom.
                      </SmallestAllCapsTypo>
                    </FooterSubtitle>
                    <FooterSubtitle isRight={true}>
                      <SmallestAllCapsTypo>
                        @2019
                      </SmallestAllCapsTypo>
                    </FooterSubtitle>
                  </FooterSubtitlesContainer>
                </MiddleContainer>
            </SectionFooterMargin>
          </ThemeContainer>
        </ThemeProvider>
      );
  }
}

export default SectionFooter;
