import styled from "styled-components";

export const ProjectImgStyle = styled.div`
  display: inline-block;
  position: relative;
  float: left;
  width: 100%;
  background-image: ${props => props.bgImg};
  background-size: cover;
  min-height: 50vh;
  transition: 200ms linear;
  background-position: center;
  background-color: #0023c1;
  background-blend-mode: difference;
  filter: grayscale(1);

  /* 1366px */
  @media (min-width: 85.375em) {
    float: ${ props => props.isLeft ? 'left' : ' right'};
    width: 50%;
    height: 80vh;
    background-position: center;
    border: ${ props => props.isHover ? '40px solid' : '0 solid'};
    border-color: ${ props => props.theme.backgroundColor };
    background-color: #0023c1;
    background-blend-mode: ${ props => props.isHover ? 'unset' : 'difference' };
    filter: ${ props => props.isHover ? 'grayscale(0)' : 'grayscale(1)' };
  }

`;

export const WideImage = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 60vh;
  max-height: 200rem;
  margin-left: 0;
  transition: 500ms;
  background-image: ${props => props.bgImg};
  background-size: cover;
  background-position: center;
  background-color: ${props => props.blend ? props.blend : '#404040'};
  background-blend-mode: difference;
  transition: 500ms;

  &:hover {
  background-color: #1c1c1c;
  }
`;

export const TeamImage = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 40vh;
  max-height: 150rem;
  background-image: ${props => props.bgImg};
  background-size: cover;
  background-position: center;
  background-color: #1c1c1c;
  background-blend-mode: difference;
  transition: 500ms;

  &:hover {
  background-color: #404040;
  }
`;

export const ProcessImage = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: 40vh;
  max-height: 150rem;
  background-image: ${props => props.bgImg};
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

export const SocialIcon = styled.div`
  svg {
     fill: ${ props => props.theme.color };
     transition: 100ms;
  }
`;

export const IconOuterCircle = styled.div`
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 5rem;
  padding: calc(2.5rem - 8px);
  border: 1px dotted ${ props => props.theme.secondaryColor };
  transform-origin: center center;
  transform: rotate(${ props => props.arrowRotation}deg);
  border-radius: 5rem;

  svg {
     fill: ${ props => props.theme.color };
     transition: 100ms;
  }
`;

export const InlineFloatIconText = styled.div`
  display: inline-block;
  position: relative;
  float: left;
  margin-right: 1.5rem;
`;

export const IconMobileMenu = styled.div`
  display: block;
  position: relative;
  width: 3rem;
  height: 3rem;
`;

export const IconMobileMenuLine1 = styled.div`
  display: block;
  position: absolute;
  width: 70%;
  height: 2px;
  background: ${ props => props.isClicked ? props.theme.backgroundColor : props.theme.color };
  top: ${ props => props.isClicked ? '45%' : '35%'};
  left: 15%;
  transform-origin: 50% 50%;
  transform: ${ props => props.isClicked ? 'rotate(45deg)' : 'rotate(0)'};
  transition: 300ms;
`;

export const IconMobileMenuLine2 = styled.div`
  display: block;
  position: absolute;
  width: ${ props => props.isClicked ? '70%' : '50%'};
  height: 2px;
  background: ${ props => props.isClicked ? props.theme.backgroundColor : props.theme.color };
  top: ${ props => props.isClicked ? '45%' : '60%'};
  left: 15%;
  transform-origin: 50% 50%;
  transform: ${ props => props.isClicked ? 'rotate(-45deg)' : 'rotate(0)'};
  transition: 300ms;
`;
