import styled from "styled-components";

export const TextContainerCenter = styled.div`
  display: block;
  position: relative;
  width: 100%;
  left: 0;
  text-align: left;

  /* 1024px */
  @media (min-width: 64em) {
    text-align: center;
  }
`;

export const TextContainerColumns = styled(TextContainerCenter)`
  text-align: left;
  overflow: hidden;
`;

export const SmallColumnsContainer = styled.div`
  display: inline-block;
  position: relative;
  float: left;
  width: 100%;
  margin-left: 0;
  overflow: hidden;
  text-align: left;

  /* 1280px */
  @media (min-width: 80em) {
    width: ${ props => props.isDescription ? '50%' : '40%' };
    margin-left: ${ props => props.isDescription ? '0' : '10%' };
  }
`;

export const TextColumn = styled.div`
  display: inline-block;
  position: relative;
  float: left;
  text-align: left;
  width: 100%;
  margin-bottom: 2rem;

  /* 1280px */
  @media (min-width: 80em) {
    width: ${ props => props.isLargeColumn ? '100%' : '50%' };
    padding-right: ${ props => props.hasPaddingRight ? '5rem' : '0' };
    margin-bottom: ${ props => props.hasBottomMargin ? '5rem' : '0' };
    min-width: 20rem;
  }
`;

export const TextColumnListTile = styled.div`
  display: inline-block;
  position: relative;
  margin: 1rem 1rem 1rem 0;

  /* 470px */
  @media (min-width: 29.375rem) {
    margin: 2rem 1rem 2rem 0;
  }

  /* 1280px */
  @media (min-width: 80em) {
  margin: 1.5rem 1rem 0 0;
  }
`;

export const TextColumnListTitle = styled.div`
  display: block;
  position: relative;
  margin: 1rem 0;

  /* 470px */
  @media (min-width: 29.375rem) {
    margin: 5rem 0;
  }

`;

export const TextColumnListDot = styled.div`
  display: inline-block;
  position: relative;
  width: 1rem;
  height: 1rem;
  border-radius: 0.5rem;
  border: 1px solid ${ props => props.theme.secondaryColor };
  margin: 0 1rem 0 0;
  top: -0.25rem;


  /* 1024px */
  @media (min-width: 64em) {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 0.25rem;
    margin: 0 0.5rem 0 0;
  }
`;
