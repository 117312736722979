import React from 'react';

import { Link } from 'gatsby';

import {
  MobileMenuOpenClose,
  MobileMenuContainer,
  MobileMenuLogo,
  MobileMenuElement
} from '../commonStyles/FixedElements';

import { MobileMenuTypo, LogoTypo } from '../commonStyles/typoTheme';

import IconMobileMenu from './IconMobileMenu';

class FixedElementsMobileMenu extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
    };
    this.openMenu = this.openMenu.bind(this);
  }

  openMenu() {
    this.setState((state) => {
      return {
        menuOpen: !state.menuOpen
      }
    });
  }

  render() {
    return (
      <>
      <MobileMenuOpenClose onClick={this.openMenu}>
          <IconMobileMenu isClicked={this.state.menuOpen}/>
      </MobileMenuOpenClose>
      <MobileMenuContainer menuOpen={this.state.menuOpen}>
        <MobileMenuLogo>
          <LogoTypo>
            <Link to={'/'}>
              Border3px
            </Link>
          </LogoTypo>
        </MobileMenuLogo>
        <MobileMenuElement  isCurrent={this.props.current === 'process'}>
          <Link to={'/process'}>
            <MobileMenuTypo>
              Process
            </MobileMenuTypo>
          </Link>
        </MobileMenuElement>
        <MobileMenuElement  isCurrent={this.props.current === 'about'}>
          <Link to={'/about'}>
            <MobileMenuTypo>
              About
            </MobileMenuTypo>
          </Link>
        </MobileMenuElement>
        <MobileMenuElement  isCurrent={this.props.current === 'contact'}>
          <Link to={'/contact'}>
            <MobileMenuTypo>
              Contact
            </MobileMenuTypo>
          </Link>
        </MobileMenuElement>
      </MobileMenuContainer>
      </>
    );
  }
}

export default FixedElementsMobileMenu;
