import styled from "styled-components";

export const ButtonStyle = styled.a`
  width: fit-content;
  text-align: center;
  background: ${ props => props.backgroundColor ? props.backgroundColor : props.theme.color };
  color: ${ props => props.textColor ? props.textColor : props.theme.backgroundColor };
  border-radius: 4rem;
  padding: 1.5rem 3rem;
  cursor: pointer;
  transition: 500ms;
  font-family: 'Jost', sans-serif;
  font-size: 2rem;
  line-height: 5rem;
  font-weight: 400;

  /* 1280px */
  @media (min-width: 80em) {
    background: ${ props => props.theme.color };
    color: ${ props => props.theme.backgroundColor };
  }

  &:hover {
    background: ${ props => props.theme.linkColor };
    color: ${ props => props.theme.backgroundColor };
  }
`;

export const CenteredButton = styled.div`
  display: block;
  position: relative;
  width: fit-content;
  margin: 5rem 0;

  /* 1024px */
  @media (min-width: 64em) {
    margin: 3rem auto;
  }
`;
