import styled from "styled-components";


export const SectionMain = styled.div`
  display: block;
  position: relative;
  width: 100%;
  padding: 0;
  overflow-x: hidden;
`;

export const SectionHeaderMargin = styled(SectionMain)`
  padding: 10rem 0 10rem 0;


  /* 1024px */
  @media (min-width: 64em) {
    padding: 15rem 0 15rem 0;
  }


  /* 1366px */
  @media (min-width: 85.375em) {
    padding: 30rem 0 20rem 0;
  }

  /* 1920px */
  @media (min-width: 120em) {
    padding: 40rem 0 30rem 0;
  }
`;

export const SectionContactMargin = styled(SectionMain)`
  display: block;
  background: ${ props => props.theme.backgroundColor };

  /* 1024px */
  @media (min-width: 64em) {
    padding: 15rem 0 15rem 0;
  }


  /* 1366px */
  @media (min-width: 85.375em) {
    padding: 30rem 0 20rem 0;
  }

  /* 1920px */
  @media (min-width: 120em) {
    padding: 40rem 0 30rem 0;
  }
`;


export const SectionAboutMargin = styled(SectionMain)`
    padding: ${ props => props.isFirst ? '10rem 0 0 0' : '7rem 0' };

  /* 1024px */
  @media (min-width: 64em) {
    padding: ${ props => props.isFirst ? '30rem 0 10rem 0' : '7rem 0 15rem 0' };
  }

  /* 1280px */
  @media (min-width: 80em) {
    padding: ${ props => props.isFirst ? '40rem 0 20rem 0' : '7rem 0 15rem 0' };
  }

  /* 1366px */
  @media (min-width: 85.375em) {
    padding: ${ props => props.isFirst ? '40rem 0 20rem 0' : '7rem 0 15rem 0' };
  }

  /* 1920px */
  @media (min-width: 120em) {
    padding: ${ props => props.isFirst ? '40rem 0 20rem 0' : '7rem 0 15rem 0' };
  }
`;



export const SectionProjectMargin = styled(SectionMain)`
  margin: 3rem 0 5rem 0;
  border-top: 1px solid #cc906d;
  padding-top: 6rem;

  /* 1024px */
  @media (min-width: 64em) {
    margin: 0 0 15rem 0;
  }

  /* 1366px */
  @media (min-width: 85.375em) {
    border-top: none;
    margin: 0 0 25rem 0;
  }

  /* 1920px */
  @media (min-width: 120em) {
    margin: 0 0 30rem 0;
  }
`;

export const SectionFooterMargin = styled(SectionMain)`
  padding: 10rem 0 0 0;

  /* 1024px */
  @media (min-width: 64em) {
    padding: 20rem 0 10rem 0;
  }
`;

/*-----------------------------------------------CASE STUDY SECTIONS BELOW-----------------------------------------------*/

export const SectionInfoCaseStudyMargin = styled(SectionMain)`
  padding: 5rem 0 5rem 0;

  /* 1280px */
  @media (min-width: 80em) {
    padding: 20rem 0 15rem 0;
  }


  /* 1366px */
  @media (min-width: 85.375em) {
    padding: 30rem 0 15rem 0;
  }

  /* 1920px */
  @media (min-width: 120em) {
    padding: 40rem 0 25rem 0;
  }
`;

export const SectionImageCaseStudyMargin = styled(SectionMain)`
  margin: 2rem 0;
  overflow: hidden;
`;

export const SectionDescCaseStudyMargin = styled(SectionMain)`
  margin: 10rem 0;

  /* 1024px */
  @media (min-width: 64em) {
    margin: 10rem 0;
  }

  /* 1366px */
  @media (min-width: 85.375em) {
    margin: 20rem 0 30rem 0;
  }

`;

export const SectionPaletteCaseStudyMargin = styled(SectionMain)`
  margin: 3rem 0;
`;

export const SectionLastCaseStudyMargin = styled(SectionMain)`
  margin: 10rem 0;

  /* 1024px */
  @media (min-width: 64em) {
    margin: 10rem 0;
  }

  /* 1366px */
  @media (min-width: 85.375em) {
    margin: 20rem 0;
  }

  /* 1920px */
  @media (min-width: 120em) {
    margin: 30rem 0;
  }
`;
