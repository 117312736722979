import React from 'react';

import { SocialIcon } from '../commonStyles/ImagesStyles';

class IconBe extends React.PureComponent {

  render() {

    return (
      <SocialIcon>
        <svg id="be_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 18.75">
          <path d="M15.46,18.68A4.43,4.43,0,0,0,12.09,14a4,4,0,0,0,2.52-3.85c0-3.66-2.74-4.56-5.9-4.56H0V24H9c3.35,0,6.51-1.61,6.51-5.35ZM4.06,8.77H7.87c1.46,0,2.78.41,2.78,2.1s-1,2.19-2.48,2.19H4.06Zm0,12.13V15.83H8.48c1.79,0,2.92.75,2.92,2.63s-1.35,2.44-3,2.44Z" transform="translate(0 -5.62)"/>
          <path d="M23.5,24.38a6,6,0,0,0,6.26-4.49H26.51a3,3,0,0,1-2.9,1.74c-2.15,0-3.28-1.25-3.28-3.39H30c.31-4.27-2.07-7.91-6.47-7.91a6.7,6.7,0,0,0-6.84,7C16.66,21.53,19.28,24.38,23.5,24.38Zm-.12-11.29c1.85,0,2.78,1.08,2.93,2.85h-6a2.86,2.86,0,0,1,3-2.85Z" transform="translate(0 -5.62)"/>
          <path d="M19.59,6.59h7.48V8.4H19.59Z" transform="translate(0 -5.62)"/>
        </svg>
      </SocialIcon>
      );
  }
}

export default IconBe;
