import React from 'react';

import { ButtonStyle } from '../commonStyles/ButtonStyles';

class Button extends React.PureComponent {
  render() {
    const {
      backgroundColor,
      textColor
    } = this.props;


    return (
      <ButtonStyle href={this.props.href} backgroundColor={backgroundColor} textColor={textColor}>
        {this.props.name}
      </ButtonStyle>
    );
  }
}

export default Button;
