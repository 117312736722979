import React from 'react';

import { SocialIcon } from '../commonStyles/ImagesStyles';

class IconFb extends React.PureComponent {

  render() {

    return (
      <SocialIcon>
        <svg id="fb_icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 19.88" display='block' position="absolute">
          <path d="M10,0A10,10,0,0,0,8.44,19.88v-7H5.9V10H8.44V7.8a3.52,3.52,0,0,1,3.77-3.89,15.72,15.72,0,0,1,2.24.19V6.56H13.19a1.45,1.45,0,0,0-1.63,1.56V10h2.78l-.45,2.89H11.56v7A10,10,0,0,0,10,0Z"/>
        </svg>
      </SocialIcon>
      );
  }
}

export default IconFb;
